<template>
  <div>
    <ValidationObserver ref="formValidator" v-slot="{ invalid, validate }">
      <form @submit.prevent="validate().then($emit('request-invoice'))">
        <base-input
          v-model="email"
          label="Accounts Payable contact email (or your email if no AP department)"
          name="Invoice email"
          rules="customRequired:Valid email address required|customEmail:Valid email address required"
        />
        <div class="d-flex justify-content-end mb-5 mt-3">
          <base-button type="secondary" @click="$router.push({name: 'Billing'})">Cancel</base-button>
          <base-button
            :disabled="invalid || disabled"
            nativeType="submit"
            type="primary"
          >{{ (disabled) ? disabledText : 'Request Invoice' }}
          </base-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/Inputs/BaseInput.vue';

export default {
  components: {
    BaseInput,
    BaseButton,
  },
  props: [
    'value',
    'disabled',
    'disabledText',
  ],
  computed: {
    email: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit('input', value);
      },
    },
  },
  method: {},
};
</script>
