<template>
  <div>
    <div class="text-center mb-4">
      <b-btn-group class="app-border primary-border w-50">
        <base-button
          :type="calculateBtnType(plansTypes.ANNUAL)"
          size="sm"
          @click="selectedPlanType = plansTypes.ANNUAL"
        >Annual
        </base-button
        >
        <base-button
          :type="calculateBtnType(plansTypes.MONTHLY)"
          size="sm"
          @click="selectedPlanType = plansTypes.MONTHLY"
        >Monthly
        </base-button
        >
      </b-btn-group>
    </div>
    <div class="d-flex flex-column">
      <plan-card
        v-for="plan in teamPlans"
        :key="`plan_${plan.title}`"
        :accountType="plan.accountType"
        :clarification="plan.clarification"
        :currentPlan="currentPlanLabel(plan.accountType, plan.planType)"
        :description="plan.description"
        :disabled="disabledCard(plan.planType)"
        :isSelected="isSelectedCard(plan)"
        :planType="selectedPlanType"
        :price="selectedPlanType === plansTypes.MONTHLY ? plan.monthlyPrice :
            plan.annualPrice * 12 || plan.monthlyPrice"
        :requirements="filteredRequirements(plan.requirements)"
        :subDescription="plan.subDescription"
        :title="plan.billingTitle || plan.title"
        @plan-selected="selectPlan(plan, ...arguments)"
      />
    </div>
    <div class="d-flex justify-content-end mt-3">
      <base-button
        v-if="!!$route.params.backLink"
        type="outline-primary"
        @click="()=>{$router.push({name: $route.params.backLink})}">Back
      </base-button>
      <base-button :disabled="disabledBtn" type="primary" @click="planSelectionHandler">{{ planBtnText }}</base-button>
    </div>
  </div>
</template>
<script>
import PlanCard from './PlanCard.vue';
import {accountTypes, plansTypes} from '@/store/userConstants';
import {mapGetters} from 'vuex';

export default {
  components: {
    PlanCard,
  },
  data() {
    return {
      plansTypes,
      selectedPlanType: plansTypes.MONTHLY,
      selectedPlan: null,
    };
  },
  computed: {
    ...mapGetters([
      'accountType',
      'planType',
      'plans',
      'currentPlan',
    ]),
    disabledBtn: function() {
      if (this.selectedPlan) {
        const {accountType, planType} = this.selectedPlan;
        if ([accountTypes.PERSONAL, accountTypes.TEAM].includes(accountType)) {
          const personalAnnual = accountType === accountTypes.PERSONAL && this.selectedPlanType === plansTypes.ANNUAL;
          const personalPAYG = personalAnnual && planType === plansTypes.PAYG;
          return personalPAYG || this.currentPlanLabel(accountType, planType) || this.switchToTeam;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    planBtnText: function() {
      let btnText = 'Select plan';
      if (this.hasAccount) {
        btnText = this.switchToTeam ? 'Contact us' : 'Change plan';
      }
      return btnText;
    },
    switchToTeam: function() {
      let res = false;
      if (this.hasAccount) {
        res = (this.accountType === accountTypes.PERSONAL &&
          this.selectedPlan &&
          this.selectedPlan.accountType === accountTypes.TEAM);
      }
      return res;
    },
    hasAccount: function() {
      return [accountTypes.PERSONAL, accountTypes.TEAM].includes(this.accountType);
    },
    teamPlans() {
      return this.plans.filter((plan) => plan.accountType === 'team');
    },
  },
  watch: {
    currentPlan: {
      handler: function(val) {
        (!val) && this.selectPlan(this.teamPlans[0]); // Added for default Team plan select
        (val) && this.selectPlan(val);
      },
      immediate: true,
    },
    planType: {
      handler: function(val) {
        if ([plansTypes.PAYG, plansTypes.MONTHLY, plansTypes.ANNUAL].includes(val)) {
          this.selectedPlanType = val === plansTypes.PAYG ? plansTypes.MONTHLY : val;
        }
      },
      immediate: true,
    },
  },
  methods: {
    calculateBtnType: function(planType) {
      return this.selectedPlanType === planType ? 'primary' : 'primary-outline';
    },
    disabledCard: function(planType) {
      return (planType === plansTypes.PAYG && this.selectedPlanType === plansTypes.ANNUAL);
    },
    currentPlanLabel: function(accountType, planType) {
      if (planType === plansTypes.PAYG) {
        return this.planType === plansTypes.PAYG && this.selectedPlanType === plansTypes.MONTHLY;
      } else {
        return this.accountType === accountType && this.planType === this.selectedPlanType;
      }
    },
    selectPlan: function({...plan}) {
      this.selectedPlan = plan;
      this.selectedPlan.selectedPlanType = this.selectedPlanType;
    },
    isSelectedCard: function(plan) {
      let selected = false;
      if (plan && this.selectedPlan && !this.disabledCard(this.selectedPlan.planType)) {
        if (plan.planType === plansTypes.PAYG) {
          selected = this.selectedPlan.planType === plansTypes.PAYG;
        } else {
          selected = plan.accountType === this.selectedPlan.accountType &&
            this.selectedPlan.planType !== plansTypes.PAYG;
        }
      }
      return selected;
    },
    planSelectionHandler: function() {
      this.selectedPlan.selectedPlanType = this.selectedPlanType;
      this.$emit('planSelected', this.selectedPlan);
    },
    filteredRequirements: function(requirements) {
      return requirements.filter((req) => req.planType === this.selectedPlanType ||
        !req.planType).map((req) => req.title);
    },
  },
};
</script>
