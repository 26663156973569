<template>
  <div>
    <b-form-group>
      <!--      <label class="form-control-label">Credit card</label>-->
      <div ref="card" class="form-control"></div>
    </b-form-group>
    <div class="d-flex justify-content-end mt-4">
      <base-button type="secondary" @click="$router.push({name: 'Billing'})">Cancel</base-button>
      <base-button :disabled="disabled" type="primary" @click="purchase">{{
          disabled
            ? 'This is your current plan'
            : 'Confirm purchase'
        }}
      </base-button>
    </div>
  </div>
</template>
<script>
import {loadStripe} from '@stripe/stripe-js';
import appNotification, {notificationsTypes} from '@/util/appNotification';

export default {
  props: {
    disabled: Boolean,
  },
  data: function() {
    return {
      stripe: null,
      elements: null,
      card: null,
    };
  },
  async mounted() {
    setTimeout(async () => {
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_API_TOKEN);
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card', {
        hidePostalCode: true,
        style: {
          base: {
            fontSize: '18px',
          },
        },
      });
      this.card.mount(this.$refs.card);
    }, 500);
  },
  methods: {
    purchase: async function() {
      const token = await this.getCardToken();
      if (token) {
        this.$emit('on-purchase', token.id);
      }
    },
    getCardToken: async function() {
      let token = null;
      try {
        const res = await this.stripe.createToken(this.card);
        if (res.error) {
          throw new Error(res.error.message);
        } else if (res.token && res.token.id) {
          token = res.token;
        }
      } catch (err) {
        appNotification.notify({message: err.message, type: notificationsTypes.danger});
      }
      return token;
    },
  },
};
</script>
<style scoped>
</style>
