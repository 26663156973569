<template>
  <card>
    <template slot="header">
      <h3 class="mb-0">
        <template>
          {{ hasPlan ? 'Plans' : 'Welcome, pick a plan to get started!' }}
        </template>
      </h3>
    </template>
    <template>
      <ZoomCenterTransition group>
        <plan-selection-screen
          v-if="plansComponentScreen === planSelectionScreenName"
          key="planSelectionScreen"
          @planSelected="planSelectionHandler"
        />
        <template
          v-else-if="plansComponentScreen === planTemplateScreenName && plan"
        >
          <plan-template-screen
            key="planTemplateScreen"
            :plan="plan"
          />
        </template>
      </ZoomCenterTransition>
    </template>
  </card>
</template>
<script>
import {ZoomCenterTransition} from 'vue2-transitions';
import PlanSelectionScreen from './PlanSelectionScreen.vue';
import PlanTemplateScreen from './PlanTemplateScreen.vue';
import {PLAN_SELECTION_SCREEN, PLAN_TEMPLATE_SCREEN} from './planSelectionScreensNames';
import {accountTypes} from '@/store/userConstants';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {CHANGE_PLANS_COMPONENT_SCREEN, FETCH_PLANS_REQUEST} from '@/store/storeActions';

export default {
  components: {
    ZoomCenterTransition,
    PlanSelectionScreen,
    PlanTemplateScreen,
  },
  props: {
    screen: {
      type: String,
      default: PLAN_TEMPLATE_SCREEN,
    },
  },
  data() {
    return {
      plan: this.getPlanFromProps(),
    };
  },
  computed: {
    ...mapGetters([
      'accountType',
      'planType',
      'plans',
      'plansLoaded',
      'plansComponentScreen',
    ]),
    planSelectionScreenName:
      function() {
        return PLAN_SELECTION_SCREEN;
      },
    planTemplateScreenName:
      function() {
        return PLAN_TEMPLATE_SCREEN;
      },
    hasPlan: function() {
      return [accountTypes.PERSONAL, accountTypes.TEAM].some((acc) => acc === this.accountType);
    },
  },
  async created() {
    if (!this.plansLoaded) {
      await this.FETCH_PLANS_REQUEST();
    }
    let plan = null;
    if (this.accountType === 'none') {
      plan = this.plans.find((plan) => plan.accountType === 'team');
      plan.selectedPlanType = 'annual';
    } else {
      plan = this.plans.find((plan) => plan.accountType === this.accountType);
      plan.selectedPlanType = this.planType;
    }
    this.planSelectionHandler(plan);
  },
  methods: {
    ...mapMutations([
      CHANGE_PLANS_COMPONENT_SCREEN,
    ]),
    ...mapActions([
      FETCH_PLANS_REQUEST,
    ]),
    changeScreen: function(screen) {
      this.CHANGE_PLANS_COMPONENT_SCREEN({screen});
    },
    planSelectionHandler: function(plan) {
      this.plan = plan;
      this.changeScreen(PLAN_TEMPLATE_SCREEN);
    },
    leftBtnHandler: function() {
      if (this.plansComponentScreen === PLAN_TEMPLATE_SCREEN) {
        this.plansComponentScreen = PLAN_SELECTION_SCREEN;
      }
    },
    getPlanFromProps: function() {
      let plan = null;
      if (this.accountType && this.planType) {
        plan = this.plans.find((plan) => plan.accountType === this.accountType);
        plan.selectedPlanType = this.planType;
      }
      return plan;
    },
  },
};
</script>
